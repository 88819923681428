/*eslint no-unused-vars: "off"*/

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
import { DisplayFormikState } from "./../formikHelper"



const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});

const ObjectStateless = (props) => {
  const { classes, intl } = props;
  const t = intl.formatMessage;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  const initialValues = {
    produkt_id: props.productId, // custom
    name: '',
    email: '',
    message: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'heuresis_produkt_zapytanie';
  // oFormHelper.formEndpoint = '';
  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    // Form schema does not contain markup elements!
    oFormHelper.getFormSchema(initialValues.webform_id);
  }, []);



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <Formik
          initialValues={initialValues}
          onSubmit={oFormHelper.onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            } = props;

            // console.log(status)

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            return (
              <Form 
                onSubmit={handleSubmit}
                className={initialValues.webform_id}
              >

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_name`}
                      label={ oFormHelper.getFieldTitle(`name`) }
                      name="name"
                      error={errors.name && touched.name}
                      className={classes.textField}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.name && touched.name) && errors.name}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField
                      id={`${initialValues.webform_id}_email`}
                      label={ oFormHelper.getFieldTitle(`email`) }
                      name="email"
                      error={errors.email && touched.email}
                      className={classes.textField}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.email && touched.email) && errors.email}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField
                      id={`${initialValues.webform_id}_message`}
                      label={ oFormHelper.getFieldTitle(`message`) }
                      name="message"
                      error={errors.message && touched.message}
                      className={classes.textField}
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.message && touched.message) && errors.message}
                      margin="normal"

                      // display as textarea
                      multiline={true}
                      rows="5"
                      rowsMax="5"

                    />
                  </div>
                </div>



                {/* Form status messages */}
                <div className="row form-status">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert">{/* {formStatus.message} */}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <DialogActions>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                      {isSubmitting ? 
                        <CircularProgress /> : 
                        <Button 
                          type="submit" 
                          variant="contained" 
                          color="primary"
                          className={formStatus && formStatus.success ? 'green' : null}
                          disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                      }

                    </DialogActions>
                  </div>
                </div>



                {/* <DisplayFormikState {...props} /> */}


                
              </Form>
            );
          }}
        </Formik>
      }
      </React.Fragment>
    )
}

export default withStyles(styles)(injectIntl(ObjectStateless))